import Web3 from "web3";
import { ContractFactory, Contract, ethers, BigNumber } from "ethers";

// Constant
import { getNetworkUrl, getContractDetails } from "helpers/contants";

class Web3Intraction {
  constructor(blockchain, provider, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);
    console.log("networkUrl", networkUrl);
    if (provider) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        provider,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );
      this.SIGNER = this.PROVIDER.getSigner();
    }

    this.settings = settings;
    this.networkUrl = networkUrl;
    this.adminContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings
    );
    console.log("this.adminContractSetting", this.adminContractSetting);
    
  // this.switchChain();
    
  }

  getTransactionReceipt = (transactionHash) => {
    return new Promise(async (resolve, reject) => {
      try {
        const receipt = await this.PROVIDER.waitForTransaction(transactionHash);
        console.log("Transaction Receipt:", receipt);
        resolve(receipt);
      } catch (error) {
        console.error("Error getting transaction receipt:", error);
        reject(error);
      }
    });
  };

  // switchChain = async () => {
  //   try {
  //     // Request switching to the specified chain
  //     await window.ethereum.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: `0x${this.networkUrl.chainId.toString(16)}` }],
  //     });

  //     // Check if the chain switch was successful
  //     const currentChainId = await ethereum.request({ method: "eth_chainId" });
  //     if (parseInt(currentChainId, 16) === chainId) {
  //       console.log(`Switched to chain ${chainId}`);
  //     } else {
  //       console.error("Failed to switch to the specified chain");
  //     }
  //   } catch (error) {
  //     console.error("Error switching chain:", error);
  //   }
  // };
  switchChain = () => {
    return new Promise((resolve, reject) => {
        const chainId = this.networkUrl.chainId; // Retrieve the chain ID from this.networkUrl.chainId
        console.log("this.networkUrl", this.networkUrl, "chainId", chainId);
          return ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: convertNumberToHex(chainId) }],
          }).then(() => {
            console.log(`Added and switched to chain ${chainId}`);
            resolve();
        }).catch(error => {
            console.error("Error switching chain:", error);
            reject(error);
        });
    });
};



  convertPriceToEther = (price) => {
    return ethers.utils.parseEther(price?.toString())._hex;
    // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
  };

  getContract = (abi, address) => {
    try {
      let contract = new Contract(address, abi, this.SIGNER);
      // console.log(this.SIGNER, "this.SIGNER");
      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  /**
   * Deploy collection contract.
   *
   * @param {object} collectionData Collection Details (ie. abi, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  deployContract = (collection, callback = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factory = new ContractFactory(
          JSON.parse(collection.data.abi),
          collection.data.bytecode,
          this.SIGNER
        );

        const contract = await factory.deploy();
        // console.log(contract, "contract");
        let receipt = await contract.deployTransaction.wait();

        // console.log(receipt, "receipt");
        callback &&
          callback(null, { txHash: receipt.transactionHash, receipt });
        resolve({ txHash: receipt.transactionHash, receipt });
      } catch (error) {
        callback && callback(error.message);
        reject(error.message);
      }
    });
  };

  /**
   * Check user approved contract transactions, if not then make transaction to approve.
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Success for approved or Fail for error
   */
  verifyApproved = (userWallet, collection, callback = null) => {
    return new Promise(async (resolve, reject) => {
      if (collection.abi && collection.data.contractAddress) {
        const contract = this.getContract(
          collection.abi,
          collection.data.contractAddress
        );

        if (!contract) {
          const error_message = "Invalid Contract";
          callback && callback(error_message);
          reject(error_message);
          return;
        }

        const isApproved = await contract.isApprovedForAll(
          userWallet,
          this.settings.walletAddress.publicKey
        );
        // console.log(isApproved, "isApproved");
        if (isApproved) {
          // console.log(collection, "<====collection");
          callback && callback(null, null);
          resolve(collection);
          return;
        }

        try {
          const transaction = await contract.setApprovalForAll(
            this.settings.walletAddress.publicKey,
            true
          );
          // console.log(transaction, "<===transaction");
          callback && callback(null, transaction);

          const receipt = await transaction.wait();

          console.log(receipt, "<===receipt");

          callback && callback(null, receipt);
          resolve(receipt);
        } catch (error) {
          callback && callback(error.message);
          reject(error.message);
        }
      } else {
        const error_message = "No Collection Data!";
        callback && callback(error_message);
        reject(error_message);
      }
    });
  };

  /*/////////////////////////////// */

  /**
   * Transfer balance to NFT owner account
   *
   * @param {object} itemData (NFT) Item details
   * @param {function} callback Callback function
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */
  sendTransaction = (price, callback = null) => {
    return new Promise(async (resolve, reject) => {
      // const adminContract = this.getContract(
      //   this.adminContractSetting.abi,
      //   this.adminContractSetting.contractAddress
      // );
      try {
        // const calculatePrice = getFeeCalucations(item, this.settings);

        // const options = {
        //   value: ethers.utils
        //     .parseUnits(item.price?.toString() || "0", "ether")
        //     .toHexString(),
        // };
        // const transaction = await adminContract.sendETH(
        //   calculatePrice.address,
        //   calculatePrice.price,
        //   options
        // );

        // const receipt = await transaction.wait();
        const priceEth = convertPriceToEther(price);
        console.log("priceEth", priceEth);
        const receipt = await this.SIGNER.sendTransaction({
          to: this.settings.walletAddress.publicKey,
          value: priceEth,
          gasLimit: Web3.utils.toWei("21000", "wei"),
        });

        callback && callback(null, receipt);
        resolve(receipt);
      } catch (error) {
        callback && callback(error?.message);
        reject(error);
      }
    });
  };

  /**
   * Check user signature
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} message Message
   * @param {function} callback Callback function
   *
   * @returns {Promise} Success for approved or Fail for error
   */

  //collection contract
  contractMethods = async (collectionData, bodyData, settingData, callback) => {
    try {
     
      console.log("collectionData", collectionData, "bodyData", bodyData);
      const contract = this.getContract(
        JSON.parse(collectionData.contractAbi),
        collectionData.contractAddress
      );
      console.log("contract", contract);
      let tx;
      switch (bodyData.action) {
        case "maxSupply":
          tx = await contract.setMaxSupply(bodyData.maxSupply);
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.maxSupply });
        case "mintMyNFT":
          tx = await contract.mintMyNFT(bodyData.mintQuantity);
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.mintQuantity });

        case "cost":
          tx = await contract.setCost(
            ethers.utils.parseEther(bodyData.cost.toString())
          );
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.cost });
        case "gameNFTCost":
          tx = await contract.setGameNFTCost(
            convertToWei(bodyData.gameNFTCost)
          );
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.gameNFTCost });
        case "easyModeGamePercent":
          tx = await contract.setEasyModePercent(
            parseInt(bodyData.easyModeGamePercent)
          );
          await tx.wait();
          return callback(null, {
            [bodyData.action]: bodyData.easyModeGamePercent,
          });
        case "hardModeGamePercent":
          tx = await contract.setHardModePercent(
            parseInt(bodyData.hardModeGamePercent)
          );
          await tx.wait();
          return callback(null, {
            [bodyData.action]: bodyData.hardModeGamePercent,
          });
        case "notRevealedUri":
          tx = await contract.setNotRevealedURI(bodyData.notRevealedUri);
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.notRevealedUri });

        case "baseURI":
          tx = await contract.setBaseURI(bodyData.baseURI);
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.baseURI });

        case "startEndTime":
          const saleStartTime = new Date(bodyData.saleStartTime).getTime();
          const saleEndTime = new Date(bodyData.saleEndTime).getTime();
          contract.scheduleSale(saleStartTime, saleEndTime);
          break;

        case "revealed":
          tx = await contract.reveal(bodyData.baseURI);
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.revealed });

        case "paused":
          console.log("collectionData.type", collectionData.type);
          switch (collectionData.type) {
            case "og_mint":
              tx = await contract.toggleMintSale();
              await tx.wait();
              return callback(null, { [bodyData.action]: !bodyData.paused });
            default:
              if (!collectionData.paused) {
                tx = await contract.pause();
                await tx.wait();
              } else {
                tx = await contract.unpause();
                await tx.wait();
              }
              return callback(null, { [bodyData.action]: !bodyData.paused });
          }

        case "isGameMintActive":
          tx = await contract.toggleGameMintSale();
          await tx.wait();
          return callback(null, {
            [bodyData.action]: !bodyData.isGameMintActive,
          });

        case "setApprovalForAll":
          tx = await contract.setApprovalForAll(bodyData.address, true);
          await tx.wait();
          return callback(null, { [bodyData.action]: true });

        case "isApprovedForAll":
          const isApproved = await contract.isApprovedForAll(
            settingData.walletAddress,
            bodyData.address
          );
          return callback(null, isApproved);

        case "isWalletOwner":
          const owner = await contract.walletOfOwner(settingData.walletAddress);
          return callback(null, owner === settingData.walletAddress);
   
        case "royalityFee":
          tx = await contract.setDefaultRoyalty(bodyData.royalityFee * 100);
          await tx.wait();
          return callback(null, { [bodyData.action]: bodyData.royalityFee}); 

        case "toggleNonReveal":
          tx = await contract.toggleNonReveal();
          await tx.wait();  
          return callback(null, { revealed: !bodyData.revealed });

        case "setBlacklistMarketplace":
          console.log("inside marketblocklist actions")
          const isInMarketPlaceA =  await contract.isMartekplaceBlacklist(bodyData.blacklistAddress);
          console.log("isInMarketPlaceA",isInMarketPlaceA)
          if(isInMarketPlaceA) return callback({message : "Address already added in blacklist"},null);
          tx = await contract.setBlacklistMarketplace(bodyData.blacklistAddress);
          await tx.wait();
          return callback(null, null); 

        case "removeBlacklistMarketplace":
          const isInMarketPlaceB =  await contract.isMartekplaceBlacklist(bodyData.removeBlackListAddress);
          console.log("isInMarketPlaceB",isInMarketPlaceB)
          if(!isInMarketPlaceB) return callback({message : "Address not exist in blacklist"},null);
          tx = await contract.removeBlacklistMarketplace(bodyData.removeBlackListAddress);
          await tx.wait();
          return callback(null, null); 

        default:
          return callback("INVALID_ACTION");
      }
    } catch (error) {
      console.log("err", error);
      return callback(error, null);
    }
  };
}
export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return ethers.utils.parseEther(price?.toString())._hex;
  // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
};

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
export const convertToWei = (number) => Web3.utils.toWei(number);
export const convertFromWei = (number, unit) =>
  Web3.utils.fromWei(number, unit || "ether");

export const convertHexToNumber = (hex) => Web3.utils.hexToNumber(hex);
export const getCurrentChainId = () => {
  return new Promise((resolve, reject) => {
      ethereum
          .request({ method: 'eth_chainId' })
          .then((chainId) => {
              resolve(chainId);
          })
          .catch((error) => {
              reject(error);
          });
  });
};

export const convertTokenIdQuantity = (logData) =>{
  try{
    const abi = ["event ExampleEvent(uint256 firstParam, uint256 secondParam)"];
    const iface = new ethers.utils.Interface(abi);
    
    const decodedLog = iface.decodeEventLog("ExampleEvent", logData);
    console.log("decodeLog",decodedLog);
    const tokenId = BigNumber.from(decodedLog[0]._hex).toNumber(); // Convert from BigNumber to number
    const quantity = BigNumber.from(decodedLog[1]._hex).toNumber(); 
      return {
        tokenId : tokenId,
        quantity : quantity
      }
  }catch(err){
    console.log("error",err);
    return {
      tokenId : null,
      quantity : 0
    }
  }
  
}