import moment from "moment-timezone";
// import worldCountries from "world-countries";

export const ROLES = {
  admin: "ADMIN",
  staff: "STAFF",
  vendor: "VENDOR",
};

export const DEFAULT_STORE_TYPE = {
  SERVICE_NAMES: "GROCERY",
  VENDOR_MENU: "GROCERY",
  CATEGORY_FORM: "GROCERY",
  PRODUCT_FORM: "GROCERY",
  STORE_SETTINGS_FORM: "GROCERY",
  VENDOR_SETTINGS_FORM: "GROCERY",
};

export const SERVICES = ["FOOD", "GROCERY", "MEDICINES"];
export const SERVICE_ICON = {
  customers: "bx bx-user-circle",
  faq: "bx bx-chat",
  contentPages: "bx bxs-book-content",
  globalSetting: "bx bx-cog",
  subAdmins: "fas fa-user-friends",
  transactions: "bx bx-transfer",
  FOOD: "mdi mdi-food",
  GROCERY: "mdi mdi-truck-delivery-outline",
  MEDICINES: "fas fa-clinic-medical",
  OTHER: "mdi-truck-fast",
  restaurant: "bx bx-user-circle",
  vendor: "bx bx-user-circle",
  // requests: "bx bx-git-pull-request",
  requests: "bx bx-shopping-bag",
  orders: "bx bx-shopping-bag",
  configuration: "bx bx-cog",
};
export const ADMIN_SERVICE_ROUTE = {
  customers: {
    title: "Customers",
    key: "customers",
    pageLocation: "Customers",
    icon: "bx bx-user-circle",
    hasProfile: true,
  },
  configuration: {
    title: "Configuration",
    key: "configuration",
    icon: "bx bx-cog",
    pageLocation: true,
    subItems: {
      faq: {
        title: "FAQs",
        key: "faqs",
        pageLocation: "Faq",
      },
      contentPages: {
        title: "Content Pages",
        key: "content-pages",
        pageLocation: "ContentPages",
        noAdd: true,
      },
      emailTemplate: {
        title: "Email Templates",
        key: "email-templates",
        pageLocation: "EmailTemplates",
        noAdd: true,
      },
      docTemplate: {
        title: "Document Templates",
        key: "document-templates",
        pageLocation: "Documents/Templates",
      },
      docTemplateFields: {
        title: "Document Templates",
        key: "document-templates/:templateId/fields",
        pageLocation: "Documents/Fields",
        isNotRequired: true,
      },
      globalSetting: {
        title: "Store Setting",
        key: "settings",
        pageLocation: "Settings",
      },
    },
  },
};
export const ADMIN_SUB_SERVICES_ROUTE = {
  restaurants: {
    title: "Restaurant",
    key: "restaurant",
    isDynamic: true,
    pageLocation: "FoodDelivery/Restaurant",
    isNotRequired: true,
    hasProfile: true,
    hasSettings: true,
  },
};
export const VENDOR_SERVICES_ROUTE = {
  orders: {
    title: "Orders",
    key: "orders",
  },
  promoCode: {
    title: "Promo Codes",
    key: "promo-codes",
    pageLocation: "FoodDelivery/PromoCodes",
  },
};
export const VENDOR_SERVICES = [
  { title: "Orders", key: "orders" },
  { title: "Category", key: "category" },
  { title: "Products", key: "products" },
  { title: "Addon", key: "add-on" },
  { title: "Promo Codes", key: "promo-codes" },
  { title: "Settings", key: "settings" },
];

export const SERVICE_NAMES = {
  FOOD: {
    vendor: "Restaurant",
    cuisine: "Cuisine",
  },
  GROCERY: {
    vendor: "Vendor",
    cuisine: "Brand",
  },
};

export const GET_SERVICE_NAME = ({ storeType, name = "vendor" }) => {
  if (!!SERVICE_NAMES[storeType] && !!SERVICE_NAMES[storeType][name]) {
    return SERVICE_NAMES[storeType][name];
  }

  return "Restaurant";
};

export const VENDOR_MENU = {
  FOOD: [
    {
      name: "Products",
      key: "productCount",
      to: `products`,
    },
    {
      name: "Category",
      key: "categoryCount",
      to: `category`,
    },
    {
      name: "Addon",
      key: "addonCount",
      to: `add-on`,
    },
  ],
  GROCERY: [
    {
      name: "Products",
      key: "productCount",
      to: `products`,
    },
    {
      name: "Category",
      key: "categoryCount",
      to: `category`,
    },
    {
      name: "Attributes",
      key: "attributeCount",
      to: `attributes`,
    },
  ],
};

export const CATEGORY_FORM = {
  FOOD: "simple",
  GROCERY: "subCat",
};

export const PRODUCT_FORM = {
  FOOD: {
    description: false,
    featured_image: true,
    images: false,
    manage_stock: false,
    stock_quantity: false,
    variations: false,
    categories: true,
    addon: true,
    cuisine: false,
    veganType: true,
  },
  GROCERY: {
    description: true,
    featured_image: true,
    images: true,
    manage_stock: true,
    stock_quantity: true,
    variations: true,
    categories: true,
    addon: false,
    cuisine: true,
    veganType: false,
  },
  SERVICEPROVIDER: {
    description: false,
    featured_image: true,
    images: false,
    manage_stock: false,
    stock_quantity: false,
    variations: false,
    categories: true,
    addon: true,
    cuisine: false,
    veganType: false,
  },
  CARRENTAL: {
    addon: true,
    categories: true,
  },
};
// export const PRODUCT_FORM = {
//   FOOD: {
//     featured_image: true,
//     images: false,
//     manage_stock: false,
//     stock_quantity: false,
//     variations: false,
//     categories: true,
//     addon: true,
//     cuisine: false,
//   },
//   GROCERY: {
//     featured_image: true,
//     images: true,
//     manage_stock: true,
//     stock_quantity: true,
//     variations: true,
//     categories: true,
//     addon: false,
//     cuisine: true,
//   },
// }

export const VENDOR_SETTINGS_FORM = {
  FOOD: {
    Cuisines: true,
  },
  GROCERY: {
    Cuisines: false,
  },
};

export const SERVICE_STATUS = [
  "Pending",
  "Confirmed",
  "In Route",
  "Arrived",
  "Completed",
];

export const SERVICE_REFUND_TYPE = [
  { label: "No Refund", value: "noRefund" },
  { label: "Full Refund", value: "fullRefund" },
  { label: "Partial Refund", value: "partialRefund" },
];

export const DELIVERY_MODES = [
  { label: "Takeaway", value: "TAKEAWAY" },
  { label: "Delivery", value: "DELIVERY" },
];

export const PAYMENT_METHODS = ["Stripe", "Pay Stack"];

export const SOCIAL_MEDIA = ["facebook", "twitter", "instagram"];

export const WEEK_NAMES = moment.weekdays();

export const LANGUAGES = [{ value: "en", label: "English" }];

export const TIMEZONES = moment.tz.names().map((name) => ({
  label: name,
  value: name,
  utc: "(GMT" + moment.tz(name).format("Z") + ")",
}));

/* export const COUNTRY_OPTIONS = worldCountries
  .filter(
    (country) => !!country.flag && country.name.common && country.idd.root
  )
  .map((country) => ({
    name: country.name.common,
    label: country.name.common,
    value: country.name.common,
    flag: country.flag,
    countryCode: country.idd.root + country.idd.suffixes[0] || "",
  })); */

export const CURRENCY_OPTIONS = [
  { value: "USD", label: "$" },
  { value: "AUD", label: "$" },
  { value: "CAD", label: "$" },
  { value: "GBP", label: "£" },
  { value: "EUR", label: "€" },
];

export const FONT_OPTIONS = [
  { label: "Arial", value: "Arial" },
  { label: "Roboto", value: "Roboto" },
];

export const INPUT_TYPES = [
  { label: "Text", value: "text" },
  { label: "Date Picker", value: "datePicker" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Select", value: "select" },
  { label: "File", value: "file" },
];

export const STORE_KEYS = {
  mailgun: [
    { label: "MAILGUN API KEY", value: "MAILGUN_API_KEY" },
    { label: "MAILGUN DOMAIN", value: "MAILGUN_DOMAIN" },
    { label: "MAILGUN FROM", value: "MAILGUN_FROM" },
  ],
  twilio: [
    { label: "Account Sid", value: "accountSid" },
    { label: "Auth Token", value: "authToken" },
  ],
  firebase: [
    { label: "API Key", value: "FCM_APIKEY" },
    { label: "Auth Domain", value: "FCM_AUTHDOMAIN" },
    { label: "Project Id", value: "FCM_PROJECTID" },
    { label: "Storage Bucket", value: "FCM_STORAGEBUCKET" },
    { label: "Messaging Sender Id", value: "FCM_MESSAGINGSENDERID" },
    { label: "App Id", value: "FCM_APPID" },
    { label: "Measurement Id", value: "FCM_MEASUREMENTID" },
    { label: "Client Email", value: "FCM_CLIENT_EMAIL" },
    { label: "Private Key", value: "FCM_PRIVATE_KEY" },
  ],
};

export const LOGS_FILTER = [
  { label: "Direct Billing", value: "UPDATE_BILLING_DIRECT" },
  { label: "Invoice Billing", value: "UPDATE_BILLING_INVOICE" },
  { label: "Rebuild Script Update", value: "REBUILD_SCRIPT_UPDATE" },
  { label: "Rebuild Script Create", value: "REBUILD_SCRIPT_CREATE" },
  { label: "Update Setting", value: "UPDATE_SETTING" },
  { label: "Update Terminology", value: "UPDATE_TERMINOLOGY" },
  { label: "Create Terminology", value: "CREATE_TERMINOLOGY" },
];

export const STORE_FIELD_NAME = [
  { label: "Status", value: "storeStatus" },
  // { label: "Billing Plan", value: "billingPlan" },
  /* { label: "Trial", value: "isTrial" }, */
];

export const STATUS_VALUE = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "InActive" },
  { value: "archived", label: "Archived" },
  { value: "blocked", label: "Blocked" },
];

export const TRIAL_VALUE = [
  { label: "Trial Only", value: true },
  { label: "Plan Only", value: false },
];

export const ACTIVITY_TYPE = {
  minted: "Minted",
  transfer: "Transfer",
  admin_comission: "Admin Comission",
  bids: "Bids",
};

export const BLOCKCHAIN_CURRENCY_CODE = {
  ethereum: "ETH",
  polygon: "MATIC",
};

export const getNetworkUrl = (type, settings) => {
  if (settings.blockchain.length === 0) {
    return null;
  }

  let blockchain = settings.blockchain.filter((ele) => {
    return ele.status === true && ele.type === type;
  });

  if (blockchain.length === 0) {
    return null;
  }

  blockchain = blockchain[0];

  if (blockchain.networkUrl.length === 0) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  let blockchainNetworkUrl = blockchain.networkUrl.filter((ele) => {
    return ele.type === settings.blockchainNetworkMode;
  });

  blockchainNetworkUrl = blockchainNetworkUrl[0];

  if (!blockchainNetworkUrl.url) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return blockchainNetworkUrl;
};

export const claimNftsTypes = [
  "claim-earlybird-mint",
  "claim-vipak47-mint",
  "claim-maxmallet-mint",
];

export const getContractDetails = (type, settings) => {
  if (settings.multiSend && settings.multiSend.contractAddress.length === 0) {
    return null;
  }

  let contractAddress = settings.multiSend.contractAddress.filter((ele) => {
    return ele.type === type;
  });

  if (contractAddress.length === 0) {
    return null;
  }

  contractAddress = contractAddress[0];

  if (!contractAddress[settings.blockchainNetworkMode]) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return {
    abi: settings.multiSend.abi,
    contractAddress: contractAddress[settings.blockchainNetworkMode],
  };
};

export const formatWalletAddress = (walletAddress) => {
  // Check if the input is a valid string
  if (typeof walletAddress !== "string") {
    return "Invalid wallet address";
  }

  // Extract the first few characters (e.g., 6 characters)
  const firstPart = walletAddress.substring(0, 15);

  // Extract the last three characters
  const lastPart = walletAddress.slice(-4);

  // Concatenate the first and last parts with ellipsis in between
  const formattedAddress = `${firstPart}...${lastPart}`;

  return formattedAddress;
};

export const sepoliaChainId = 11155111;

export const listArmorsNfts = [
  {
    tokenId: 1,
    name: "Box1",
    nft_type: "special-power"
  },
  {
    tokenId: 2,
    name: "Box02",
    nft_type: "special-power"
  },
  {
    tokenId: 3,
    name: "Box03",
    nft_type: "special-power"
  },
  {
    tokenId: 4,
    name: "Box04",
    nft_type: "special-power"
  },
  {
    tokenId: 5,
    name: "Box05",
    nft_type: "special-power"
  },
  {
    tokenId: 6,
    name: "Box06",
    nft_type: "special-power"
  },
  {
    tokenId: 7,
    name: "Box07",
    nft_type: "special-power"
  },
  {
    tokenId: 8,
    name: "Box08",
    nft_type: "special-power"
  },
  {
    tokenId: 9,
    name: "Box09",
    nft_type: "special-power"
  },
  {
    tokenId: 10,
    name: "Box10",
    nft_type: "special-power"
  },
  {
    tokenId: 11,
    name: "Box11",
    nft_type: "special-power"
  },
  {
    tokenId: 12,
    name: "Box12",
    nft_type: "special-power"
  },
  {
    tokenId: 13,
    name: "Box13",
    nft_type: "special-power"
  },
  {
    tokenId: 14,
    name: "Box14",
    nft_type: "special-power"
  },
  {
    tokenId: 15,
    name: "Box15",
    nft_type: "special-power"
  },
  {
    tokenId: 16,
    name: "WaterWrangler",
    nft_type: "special-power"
  },
  {
    tokenId: 17,
    name: "BlazeBarrage",
    nft_type: "special-power"
  },
  {
    tokenId: 18,
    name: "Luminous",
    nft_type: "special-power"
  },
  {
    tokenId: 19,
    name: "Terra Ascension Aura",
    nft_type: "special-power"
  },
  {
    tokenId: 20,
    name: "Fortune Falls Flurry",
    nft_type: "special-power"
  },
  {
    tokenId: 21,
    name: "Lightning Lash",
    nft_type: "special-power"
  },
  {
    tokenId: 22,
    name: "Quantum Leap",
    nft_type: "special-power"
  },
  {
    tokenId: 23,
    name: "Mannequin01",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 24,
    name: "Mannequin02",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 25,
    name: "Mannequin03",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 26,
    name: "Mannequin04",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 27,
    name: "Mannequin05",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 28,
    name: "Mannequin06",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 29,
    name: "Mannequin07",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 30,
    name: "Mannequin08",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 31,
    name: "Mannequin09",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 32,
    name: "Mannequin10",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 33,
    name: "Mannequin11",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 34,
    name: "Mannequin12",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 35,
    name: "Mannequin13",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 36,
    name: "Mannequin14",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 37,
    name: "Mannequin15",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 38,
    name: "Mannequin16",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 39,
    name: "NoblePlume Mace",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 40,
    name: "TurboTech Tail",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 41,
    name: "RadiantResilience Armor",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 42,
    name: "Infinifeather Staff",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 43,
    name: "GlacierGuard Shield",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 44,
    name: "StarSpangled Suit",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 45,
    name: "Shadow Strike Gauntlets",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 46,
    name: "StellarSpike Spine",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 47,
    name: "Toxic Inferno Cannons",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 48,
    name: "BowlCape Drape",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 49,
    name: "ForceHop Pouch",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 50,
    name: "GothamGuardian Cape",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 51,
    name: "HazeBlaze Hammer",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 52,
    name: "Medusa's Pointed Pelerine",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 53,
    name: "HareBot Helper",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 54,
    name: "PiranhaPlant Blaster",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 55,
    name: "TimeTail Thrusters",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 56,
    name: "CrashCable Cluster",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 57,
    name: "CrimsonCollar Cape",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 58,
    name: "Flaming Phoenix Wings",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 59,
    name: "Golden Pika Tail",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 60,
    name: "BrainBoost Tubing",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 61,
    name: "RoboRabbit Cape",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 62,
    name: "Mutant Spine Spikes",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 63,
    name: "Jason's Terror Tools",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 64,
    name: "Golden Guardian Throne",
    nft_type: "weapons-and-gear"
  },
  {
    tokenId: 65,
    name: "Early Bird Diamond Scythe",
    nft_type: "claim-earlybird-mint"
  },
  {
    tokenId: 66,
    name: "Max Mint Mallet",
    nft_type: "claim-maxmallet-mint"
  },
  {
    tokenId: 67,
    name: "VIP Golden AK47",
    nft_type: "claim-vipak47-mint"
  },
  {
    tokenId: 68,
    name: "Villain Foxfire Blade",
    nft_type: "villain-foxfire-blade"
  },
  {
    tokenId: 69,
    name: "CarroTech",
    nft_type: "carrotech"
  },
  {
    tokenId: 70,
    name: "SkyHopper",
    nft_type: "legendary-drone"
  },
  {
    tokenId: 71,
    name: "GoldenGlider",
    nft_type: "legendary-drone"
  },
  {
    tokenId: 72,
    name: "SpectraFly",
    nft_type: "legendary-drone"
  }
];
