import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import { Label, FormGroup } from "reactstrap";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { InputGroup, Table, Input, Spinner, Button, Badge } from "reactstrap";

import moment from "moment-timezone";

//Import Images
// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";
import profile1 from "assets/images/profile-img.png";

// Web3
import Web3Intraction from "util/web3Intraction";
import Web3InteractionEth from "util/web3InteractionEth";
import toastr from "toastr";
import ConfigNft from "./ConfigNft";

// Actions
import {
  getCollection,
  postCollectionAction,
  getSettings,
  addDiscordVillainRequest,
  removeDiscordVillainRequest,
  putSettings,
} from "store/actions";
import ConfirmModal from "../List/ConfirmModal";
import { walletHooks } from "util/walletHooks";

const ViewCollection = (props) => {
  const {
    match: { params },
    onGetCollection,
    addDiscordVillainRequest,
    removeDiscordVillainRequest,
    addDisVillainLoading,
    removeDisVillainLoading,
    collection,
    loading,
  } = props;

  const [actionLoading, setactionLoading] = useState(false);
  const [fields, setfields] = useState({
    cost: "",
    gameNFTCost: "",
    notRevealedUri: "",
    baseURI: "",
    revealed: false,
    paused: false,
    maxSupply: 0,
    saleStartTime: null,
    saleEndTime: null,
    isGameMintActive: false,
    easyModeGamePercent: 0,
    hardModeGamePercent: 0,
    royalityFee : 0,
    blacklistAddress : "",
    removeBlackListAddress : ""
  });

  const [fieldsMsg, setfieldsMsg] = useState({
    cost: false,
    notRevealedUri: false,
    baseURI: false,
    revealed: false,
    paused: false,
    maxSupply: false,
    saleStartTime: false,
    saleEndTime: false,
    timezone: moment.tz.guess(),
  });
  const [villainDiscordId, setVillainDiscordId] = useState("");
  const [selectedDiscordIds, setSelectedDiscordIds] = useState([]);
  const [loader, setLoader] = useState({
    loadingAddDiscordUsers: false,
    loadingRemoveDiscordUsers: false,
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    discordId: null,
  });

  const dispatch = useDispatch();
  const toggleConfirmModal = (collectionId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      collectionId,
    }));
  };
  const wallet = walletHooks();
  const removeConfirm = async () => {
    const { discordId, isOpen } = confirmModal;

    const onComplete = () => {
      setConfirmModal({ isOpen: false, collectionId: null });
    };

    try {
      const findDiscord = selectedDiscordIds.find(
        (item) => item?.discord_user_id === discordId
      );
      console.log("findDiscord", findDiscord);
      if (!findDiscord?.savedInDb) {
        //remove from state only.
        const removeFromState = selectedDiscordIds.filter(
          (item) => item?.discord_user_id !== discordId
        );
        setSelectedDiscordIds(removeFromState);
        onComplete();
        return;
      }
      setLoader((prev) => ({ ...prev, loadingRemoveDiscordUsers: true }));
      const web3InstanceEth = new Web3InteractionEth(
        collection.blockchain,
        window.ethereum,
        props.settings
      );
      const contract = await web3InstanceEth.getContract(
        JSON.parse(collection.contractAbi),
        collection.contractAddress
      );
      console.log("contract", contract);
      const checkDiscordId = await contract.userStatus(discordId);
      console.log("checkDiscorId", checkDiscordId);

      if (checkDiscordId && checkDiscordId[0] !== "") {
        // Call the method to check for errors
        const result = await contract.callStatic["RemoveUserFromList"](
          discordId
        );

        console.log("Method call successful:", result);

        const transaction = await contract.RemoveUserFromList(discordId, {
          gasLimit: 3000000,
        });
        console.log("after remove");

        // Wait for the transaction to be mined
        const receipt = await transaction.wait();
        setLoader((prev) => ({ ...prev, loadingRemoveDiscordUsers: false }));
        console.log("receipt", receipt);
        // Get the transaction hash
        const transactionHash = receipt.transactionHash;

        console.log("after remove", transactionHash);
        if (transactionHash) {
          removeDiscordVillainRequest(
            {
              collectionId: collection?._id,
              discordUserId: discordId,
            },
            () => {
              onComplete();
            }
          );
        }
      } else {
        removeDiscordVillainRequest(
          {
            collectionId: collection?._id,
            discordUserId: discordId,
          },
          () => {
            onComplete();
          }
        );
      }
    } catch (error) {
      setLoader((prev) => ({ ...prev, loadingRemoveDiscordUsers: false }));
      console.log("error while add", error);
      toastr.error(error?.message || "Internal Error!");
    }
  };

  useEffect(() => {
    props.onGetSettings();
  }, []);

  useEffect(() => {
    if (params && params.id) {
      onGetCollection(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (collection) {
      if (collection?.allowed_discord_users) {
        setSelectedDiscordIds(
          collection.allowed_discord_users?.map((item) => ({
            ...item,
            savedInDb: true,
          }))
        );
      }
      if (
        collection.contractAddress &&
        collection.receiptStatus === "success" &&
        JSON.stringify(props.settings) !== "{}"
      ) {
        (async () => {
          const web3Intraction = new Web3Intraction(
            collection.blockchain,
            props.settings
          );

          setactionLoading(true);

          try {
            const contract = await web3Intraction.getContract(
              collection.contractAbi,
              collection.contractAddress
            );

            console.log("contract", contract);
            if (!contract) return setactionLoading(false);

            let cost;
            try {
              cost = await contract.methods.cost().call();
            } catch (er) {}
            let gameNFTCost;
            try {
              gameNFTCost = await contract.methods.gameNFTCost().call();
            } catch (er) {}

            let easyModeGamePercent;
            try {
              easyModeGamePercent = await contract.methods
                .easyModeGamePercent()
                .call();
            } catch (er) {}
            let hardModeGamePercent;
            try {
              hardModeGamePercent = await contract.methods
                .hardModeGamePercent()
                .call();
            } catch (er) {}

            let paused;
            if (collection?.type === "og_mint") {
              try {
                const isMintActive = await contract.methods
                  .isMintActive()
                  .call();
                paused = !isMintActive;
              } catch (er) {}
            } else {
              try {
                paused = await contract.methods.paused().call();
              } catch (er) {}
            }

            let notRevealedUri;
            try {
              notRevealedUri = await contract.methods.notRevealedUri().call();
            } catch (er) {}
            let baseURI;
            try {
              baseURI = await contract.methods.baseURI().call();
            } catch (er) {}
            let revealed;
            try {
              revealed = await contract.methods.revealed().call();
            } catch (er) {}
            let maxSupply;
            try {
              maxSupply = await contract.methods.maxSupply().call();
            } catch (er) {}
            let saleStartTime;
            try {
              saleStartTime = await contract.methods.saleStartTime().call();
            } catch (er) {}
            let saleEndTime;
            try {
              saleEndTime = await contract.methods.saleEndTime().call();
            } catch (er) {}

            let isGameMintActive;
            try {
              isGameMintActive = await contract.methods
                .isGameMintActive()
                .call();
            } catch (er) {}
            // const tokenURI = await contract.methods.tokenURI(1).call();
            // console.log(tokenURI, "tokenURI");
            setfields({
              cost: cost ? web3Intraction.web3.utils.fromWei(cost) : 0,
              gameNFTCost: gameNFTCost
                ? web3Intraction.web3.utils.fromWei(gameNFTCost)
                : 0,
              easyModeGamePercent,
              hardModeGamePercent,
              notRevealedUri,
              baseURI,
              revealed,
              paused,
              maxSupply: maxSupply,
              saleStartTime: moment
                .unix(saleStartTime)
                .format("YYYY-MM-DDTHH:mm"),
              saleEndTime: moment.unix(saleEndTime).format("YYYY-MM-DDTHH:mm"),
              timezone: moment.tz.guess(),
              isGameMintActive,
              royalityFee : collection?.royalityFee
            });
            // setfieldsMsg({
            //   cost: web3Intraction.web3.utils.fromWei(cost) != collection.cost,
            //   notRevealedUri:
            //     !!collection.notRevealedUri &&
            //     notRevealedUri != collection.notRevealedUri,
            //   baseURI: !!collection.baseURI && baseURI != collection.baseURI,
            //   // revealed: revealed != collection.revealed,
            //   paused: paused != collection.paused,
            //   maxSupply: maxSupply != collection.maxSupply,
            // });
            setactionLoading(false);
          } catch (err) {
            setactionLoading(false);
            console.log("Contract error", err);
          }
        })();
      }
    }
  }, [collection, props.settings]);
  console.log(fields, "fields");
  const handleChange = (name) => (evt) => {

    if(name === "royalityFee" && (evt.target.value > 99 || evt.target.value < 0)){
          return toastr.error("Please enter royality between 0 to 99"); 
    }
    setfields((prevState) => ({ ...prevState, [name]: evt.target.value }));

    if (name === "saleStartTime") {
      setfields((prevState) => ({
        ...prevState,
        saleEndTime: "",
      }));
    }
  };

  const handleSubmit = (action) => async (evt) => {
    evt.preventDefault();

    try{
      if (action === "startEndTime") {
        fields.timezone = moment.tz.guess();
        if (!fields.saleStartTime || !fields.saleEndTime)
          return toastr.error("Both Dates are required");
      }
      if (action == "revealed") {
        if (!fields.baseURI) return toastr.error("Base url is required!");
  
        // if(moment(fields.saleEndTime).unix()>moment().unix()){
        //   return toastr.error("Metadata not reveal, Sale is running now!");
        // }
      }
      if (!window.ethereum) {
        return toastr.error("Provider not found!");
      }
      setactionLoading(true);
      const web3InstanceEth = new Web3InteractionEth(
        collection.blockchain,
        window.ethereum,
        props.settings
      );
  
      await web3InstanceEth.switchChain();
      const data = {
        itemId: params.id,
        type: collection.blockchain,
        action,
        ...fields,
        maxSupply: parseInt(fields.maxSupply),
      };
      const contract = web3InstanceEth.getContract(
        JSON.parse(collection.contractAbi),
        collection.contractAddress
      );
      console.log("contract",contract);
      const owner = await contract.owner();
      console.log("owner",owner);
      console.log("wallet addre",wallet.account);
      if(wallet.account?.toLowerCase() !== owner?.toLowerCase()){
         return toastr.error("Only Owner can do this action!")
      }
      await web3InstanceEth.contractMethods(
        collection,
        data,
        props.settings,
        (err, data) => {
          setactionLoading(false);
          if (err) {
            toastr.error(err?.message || "Internal Error!");
          } else {
            console.log("data", data);
            let payload = {
              collectionId: params.id,
              ...data,
            };
  
            if (action === "revealed") {
              payload = { ...payload, baseURI: fields.baseURI };
            }
            if (action === "paused") {
              payload = { ...payload, paused: data.paused };
            }
            console.log("actionactionactionactionactionaction",action)
            if(action === "setBlacklistMarketplace"){
               setfields(prev => ({ ...prev, 
                 blacklistAddress : "",
                }))
            }
            if(action === "removeBlacklistMarketplace"){
              setfields(prev => ({ ...prev, 
                removeBlackListAddress : "",
               }))
           }
            
            props.onPostCollectionAction(payload, () => {
              onGetCollection(params.id);
            });
            const play_session = props.settings?.gamePlaySettings?.play_session;
             let gamePlaySettings = props.settings?.gamePlaySettings;
              gamePlaySettings = {...gamePlaySettings, }
            if(action === "gameNFTCost" && play_session && props.settings._id){
               const payld = play_session === "public" ? 
               {
                  _id : props.settings._id,
                  gamePlaySettings : {
                    ...gamePlaySettings,
                    public_mint_price : data.gameNFTCost
                  } 
               } : 
               {
                 _id : props.settings._id,
                 gamePlaySettings : {
                  ...gamePlaySettings, 
                  whitelist_mint_price: data.gameNFTCost
                }
               };
              dispatch(putSettings(payld));
            }
          }
          console.log("data after contract action", data);
        }
      );
    }catch(e){
      console.log("error",e)
      setactionLoading(false);
      toastr.error(e?.message || "Something went wrong!")
    }
   
  };

  console.log("selectedDiscordIds", selectedDiscordIds);

  const onChangeVillainDiscordId = (e) => {
    setVillainDiscordId(e.target.value?.trim());
  };

  const onCheckAndAddVillainDiscordId = async () => {
    if (!villainDiscordId) {
      return toastr.error("Discord id required!");
    }
    const findAlreadyAdded = selectedDiscordIds?.find(
      (item) => item?.discord_user_id === villainDiscordId
    );

    if (findAlreadyAdded) {
      toastr.error("Already verified!");
      return;
    }
    const web3InstanceEth = new Web3InteractionEth(
      collection.blockchain,
      window.ethereum,
      props.settings
    );
    const contract = await web3InstanceEth.getContract(
      JSON.parse(collection.contractAbi),
      collection.contractAddress
    );
    console.log("contract", contract);
    const checkDiscordId = await contract.userStatus(villainDiscordId);
    console.log("checkDiscorId", checkDiscordId);

    if (checkDiscordId && checkDiscordId[0] === "") {
      const payload = {
        mint_status: false,
        discord_user_id: villainDiscordId,
        savedInDb: false,
      };

      setSelectedDiscordIds((prev) => [...prev, payload]);
    } else {
      toastr.error("This discord id already addedd in the contract");
    }
  };

  const onAddDiscordUsersBlockchain = async () => {
    try {
      const addedDiscordIds =
        collection?.allowed_discord_users?.map(
          (dUser) => dUser?.discord_user_id
        ) || [];
      console.log(
        "addedDiscordIds",
        addedDiscordIds,
        "selectedDiscordIds",
        selectedDiscordIds
      );
      const newDiscordIdsForAdd = selectedDiscordIds?.filter(
        (item) => !addedDiscordIds.includes(item?.discord_user_id)
      );

      if (!newDiscordIdsForAdd?.length) {
        return toastr.error("There is no new discord user id to add!");
      }
      setLoader((prev) => ({ ...prev, loadingAddDiscordUsers: true }));
      const web3InstanceEth = new Web3InteractionEth(
        collection.blockchain,
        window.ethereum,
        props.settings
      );
      const contract = await web3InstanceEth.getContract(
        JSON.parse(collection.contractAbi),
        collection.contractAddress
      );

      const discordIds = newDiscordIdsForAdd?.map(
        (item) => item?.discord_user_id
      );
      const result = await contract.callStatic["addUser"](discordIds);
      console.log("Successfully check : ", result);

      console.log("contract", contract);
      const transaction = await contract.addUser(discordIds, {
        gasLimit: 3000000,
      });

      // Wait for the transaction to be mined
      const receipt = await transaction.wait();
      console.log("receipt", receipt);
      setLoader((prev) => ({ ...prev, loadingAddDiscordUsers: false }));
      // Get the transaction hash
      const transactionHash = receipt.transactionHash;
      if (transactionHash) {
        addDiscordVillainRequest(
          {
            collectionId: collection?._id,
            usersToAdd: discordIds,
          },
          (response) => {
            console.log("Success add discord user ids", response);
          }
        );
      }
      console.log("after add", transactionHash);
    } catch (error) {
      setLoader((prev) => ({ ...prev, loadingAddDiscordUsers: false }));
      console.log("error while add", error);
      toastr.error(error?.message || "Internal Error!");
    }
  };

  const onRemoveDiscordId = async (discordUserId) => {
    setConfirmModal({ isOpen: true, discordId: discordUserId });
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
        loader={removeDisVillainLoading || loader.loadingRemoveDiscordUsers}
      />
      <div className="page-content">
        <Container fluid className="spinner-content">
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <Breadcrumbs
            title="Collections"
            breadcrumbItem="Collection Details"
          />

          <Row>
            <Col xl="4">
              <Row>
                <Col>
                  <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                      <Row>
                        <Col xs="7"></Col>
                        <Col xs="5" className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>

                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="12" style={{ display: "flex" }}>
                          <div className="avatar-md profile-user-wid mb-4">
                            <img
                              style={{ height: "100%" }}
                              src={
                                !!collection?.image
                                  ? collection.image.link
                                  : null
                              }
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>

                          <h5 className="pl-3 font-size-15 text-truncate text-capitalize">
                            {collection?.name}
                          </h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="7">
                          <h5 className="font-size-15">
                            {moment(collection?.date_created)
                              .utcOffset(330)
                              .format("DD MMM YYYY hh:mm A")}
                          </h5>
                          <p className="text-muted mb-0">
                            {props.t("Created")} {props.t("At")}
                          </p>
                        </Col>

                        <Col xs="5">
                          <h5 className="font-size-15 text-capitalize">
                            {collection?.status || "Active"}
                          </h5>
                          <p className="text-muted mb-0">{props.t("Status")}</p>
                        </Col>
                      </Row>

                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>Contract Address</h5>

                        <p className="text-muted">
                          {collection?.contractAddress || "N/A"}{" "}
                          {collection?.receiptStatus === "pending" && (
                            <Badge color="warning">InProcess</Badge>
                          )}
                        </p>
                      </div>

                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>Description</h5>

                        <p className="text-muted">{collection?.description}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col>{props.t("Information")}</Col>
                        </Row>
                      </CardTitle>

                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">{props.t("Full Name")} :</th>
                              <td className="text-wrap">{collection?.name}</td>
                            </tr>

                            <tr>
                              <th scope="row">{props.t("Symbol")} :</th>
                              <td className="text-wrap">
                                {collection?.symbol}
                              </td>
                            </tr>
                            {collection?.type === "og_mint" && (
                              <>
                                <tr>
                                  <th scope="row">
                                    {props.t("Cost per mint")} :
                                  </th>
                                  <td className="text-wrap">
                                    {fields?.cost}
                                  </td>
                                </tr>
                                {collection?.type !== "armors" && 
                                <tr>
                                  <th scope="row">{props.t("Max Supply")} :</th>
                                  <td className="text-wrap">
                                    {fields?.maxSupply}
                                  </td>
                                </tr>
}
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            {collection?.type !== "claim_weapon" && (
              <Col xl="8">
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody className="spinner-content">
                        {!loading && actionLoading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}

                        <CardTitle className="text-primary mb-4">
                          Actions
                        </CardTitle>

                        <Row>
                          {collection?.type !== "claim_weapon" && (
                            <>
                             {collection?.type !== "armors" && 
                              <Col md={12} className="mb-4">
                                <FormGroup>
                                  <Label> {props.t("Max Supply")}: </Label>
                                  <InputGroup>
                                    <Input
                                      // className={
                                      //   fieldsMsg.maxSupply ? "is-invalid" : ""
                                      // }
                                      type="number"
                                      value={fields.maxSupply}
                                      onChange={handleChange("maxSupply")}
                                      min={0}
                                      step={0.01}
                                    />
                                    <div className="invalid-feedback">
                                      {collection.maxSupply} Update inprocess
                                    </div>
                                    <Button
                                      color="primary"
                                      onClick={handleSubmit("maxSupply")}
                                    >
                                      Update
                                    </Button>
                                  </InputGroup>
                                </FormGroup>
                                <hr />
                              </Col>
}
                              {collection?.type === "og_mint" && (
                                <>
                                  <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Cost per mint")}:{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          // className={fieldsMsg.cost ? "is-invalid" : ""}
                                          type="number"
                                          value={fields.cost}
                                          onChange={handleChange("cost")}
                                          min={0}
                                          step={0.01}
                                        />
                                        <div className="invalid-feedback">
                                          {collection.cost} Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit("cost")}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col>
                                  <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Game cost per mint")}:{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          // className={fieldsMsg.cost ? "is-invalid" : ""}
                                          type="number"
                                          value={fields.gameNFTCost}
                                          onChange={handleChange("gameNFTCost")}
                                          min={0}
                                          step={0.01}
                                        />
                                        <div className="invalid-feedback">
                                          Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit("gameNFTCost")}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col>
                                  <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Royalty Fee")}:{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          // className={fieldsMsg.cost ? "is-invalid" : ""}
                                          type="number"
                                          value={fields.royalityFee}
                                          onChange={handleChange("royalityFee")}
                                          min={0}
                                          max={99}
                                          maxLength={2}
                                        />
                                        <div className="invalid-feedback">
                                          Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit("royalityFee")}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col>
                                  <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Marketlist Blacklist")}:{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          placeholder="Enter a wallet address"
                                          value={fields.blacklistAddress}
                                          onChange={handleChange("blacklistAddress")}
                                        />
                                        <div className="invalid-feedback">
                                          Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit("setBlacklistMarketplace")}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col>
                                  <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Remove Marketlist Blacklist")}:{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          placeholder="Enter a wallet address"
                                          value={fields.removeBlackListAddress}
                                          onChange={handleChange("removeBlackListAddress")}
                                        />
                                        <div className="invalid-feedback">
                                          Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit("removeBlacklistMarketplace")}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col>
                                  {/* <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Easy Mode Game Discount (%)")}
                                        :{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          // className={fieldsMsg.cost ? "is-invalid" : ""}
                                          type="number"
                                          value={fields.easyModeGamePercent}
                                          onChange={handleChange(
                                            "easyModeGamePercent"
                                          )}
                                          min={0}
                                          step={0.01}
                                        />
                                        <div className="invalid-feedback">
                                          Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit(
                                            "easyModeGamePercent"
                                          )}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col>
                                  <Col md={12} className="mb-4">
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        {props.t("Hard Mode Game Discount (%)")}
                                        :{" "}
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          // className={fieldsMsg.cost ? "is-invalid" : ""}
                                          type="number"
                                          value={fields.hardModeGamePercent}
                                          onChange={handleChange(
                                            "hardModeGamePercent"
                                          )}
                                          min={0}
                                          step={0.01}
                                        />
                                        <div className="invalid-feedback">
                                          Update inprocess
                                        </div>

                                        <Button
                                          color="primary"
                                          onClick={handleSubmit(
                                            "hardModeGamePercent"
                                          )}
                                        >
                                          Update
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>

                                    <hr />
                                  </Col> */}
                                </>
                              )}
                              <Col md={6} className="mb-4">
                                <FormGroup>
                                  <Label> {props.t("IPFS Base Url")}: </Label>
                                  <InputGroup>
                                    <Input
                                      // className={
                                      //   fieldsMsg.baseURI ? "is-invalid" : ""
                                      // }
                                      value={fields.baseURI}
                                      onChange={handleChange("baseURI")}
                                    />
                                    <div className="invalid-feedback">
                                      {collection.baseURI} Update inprocess
                                    </div>
                                  </InputGroup>
                                </FormGroup>
                                <hr />
                              </Col>

                              <Col md={6} className="mb-4">
                                <FormGroup>
                                  <Label> {props.t("Is revealed")}:</Label>
                                  <InputGroup>
                                    <Input
                                      // className={
                                      //   fieldsMsg.revealed ? "is-invalid" : ""
                                      // }
                                      value={fields?.revealed ? "Yes" : "No"}
                                      disabled
                                    />
                                    <div className="invalid-feedback">
                                      {collection.baseURI} Update inprocess
                                    </div>
                                    <Button
                                      // disabled={fields.revealed}
                                      color="primary"
                                      onClick={handleSubmit("revealed")}
                                    >
                                      Reveal Now
                                    </Button>
                                    {collection?.type === "og_mint" && (
                                    <Button
                                      // disabled={fields.revealed}
                                      color="primary"
                                      onClick={handleSubmit("toggleNonReveal")}
                                    >
                                      Toggle
                                    </Button>
                                    )}
                                  </InputGroup>
                                </FormGroup>
                                <hr />
                              </Col>
                              <Col md={6} className="mb-4">
                                <FormGroup>
                                  <Label>
                                    {" "}
                                    {props.t("Not Revealed Url")}:{" "}
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      // className={
                                      //   fieldsMsg.notRevealedUri ? "is-invalid" : ""
                                      // }
                                      value={fields.notRevealedUri}
                                      onChange={handleChange("notRevealedUri")}
                                      min={0}
                                    />
                                    <div className="invalid-feedback">
                                      {collection.notRevealedUri} Update
                                      inprocess
                                    </div>

                                    <Button
                                      color="primary"
                                      onClick={handleSubmit("notRevealedUri")}
                                    >
                                      Update
                                    </Button>
                                  </InputGroup>
                                </FormGroup>

                                <hr />
                              </Col>
                            </>
                          )}
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Label> {props.t("Is paused")}: </Label>
                              <InputGroup>
                                <Input
                                  // className={fieldsMsg.paused ? "is-invalid" : ""}
                                  value={fields?.paused ? "Yes" : "No"}
                                  disabled
                                />

                                <div className="invalid-feedback">
                                  Update inprocess
                                </div>

                                <Button
                                  color="primary"
                                  onClick={handleSubmit("paused")}
                                >
                                  Toggle
                                </Button>
                              </InputGroup>
                            </FormGroup>

                            <hr />
                          </Col>
                          {collection?.type === "og_mint" && (
                            <Col md={6} className="mb-4">
                              <FormGroup>
                                <Label>
                                  {" "}
                                  {props.t("Is Game Mint Active")}:{" "}
                                </Label>
                                <InputGroup>
                                  <Input
                                    // className={fieldsMsg.paused ? "is-invalid" : ""}
                                    value={
                                      fields?.isGameMintActive ? "Yes" : "No"
                                    }
                                    disabled
                                  />

                                  <div className="invalid-feedback">
                                    Update inprocess
                                  </div>

                                  <Button
                                    color="primary"
                                    onClick={handleSubmit("isGameMintActive")}
                                  >
                                    Toggle
                                  </Button>
                                </InputGroup>
                              </FormGroup>

                              <hr />
                            </Col>
                          )}
                          {collection?.type === "claim_villain" && (
                            <>
                              <Col md={12} className="mb-4">
                                <FormGroup>
                                  <Label>
                                    {" "}
                                    {props.t(
                                      "Villain Allowed Discord Users"
                                    )}:{" "}
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      value={villainDiscordId}
                                      name="villainDiscordId"
                                      onChange={onChangeVillainDiscordId}
                                    />
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        onCheckAndAddVillainDiscordId()
                                      }
                                    >
                                      Verify
                                    </Button>
                                    <Button
                                      disabled={
                                        loader.loadingAddDiscordUsers ||
                                        addDisVillainLoading
                                      }
                                      color="primary"
                                      onClick={() =>
                                        onAddDiscordUsersBlockchain()
                                      }
                                    >
                                      {loader.loadingAddDiscordUsers ||
                                        (addDisVillainLoading && (
                                          <Spinner
                                            size={"sm"}
                                            className="mr-2"
                                          />
                                        ))}
                                      Add to blockchain
                                    </Button>
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col md={12}>
                                <div className="d-inline flex-wrap align-items-baseline">
                                  <hr />
                                  {selectedDiscordIds?.map((item) => (
                                    <Badge className="m-2 p-1">
                                      <span>{item?.discord_user_id}</span>

                                      <span
                                        className="cursor-pointer"
                                        onClick={() =>
                                          onRemoveDiscordId(
                                            item?.discord_user_id
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="19"
                                          viewBox="0 0 512 512"
                                        >
                                          <path
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="32"
                                            d="M368 368L144 144m224 0L144 368"
                                          />
                                        </svg>
                                      </span>
                                    </Badge>
                                  ))}
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
            {/* <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle className="text-primary mb-4">
                    {props.t("NFT Config")}
                  </CardTitle>

                  <ConfigNft />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ViewCollection.propTypes = {
  collection: PropTypes.any,
  date: PropTypes.string,
  onGetCollection: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ Settings, collections }) => ({
  loading: collections.loading || Settings.loading,
  collection: collections.collection,
  date: collections.date,
  error: collections.error,
  settings: Settings.settings,
  whitelist: collections.whitelist,
  whitelistcount: collections.whitelistcount,
  addDisVillainLoading: collections.addDisVillainLoading,
  removeDisVillainLoading: collections.removeDisVillainLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onGetCollection: (data) => dispatch(getCollection(data)),
  onPostCollectionAction: (data, callback) =>
    dispatch(postCollectionAction(data, callback)),
  addDiscordVillainRequest: (data) => dispatch(addDiscordVillainRequest(data)),
  removeDiscordVillainRequest: (data) =>
    dispatch(removeDiscordVillainRequest(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewCollection))
);
