import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

//i18n
import { withTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  getNftsMetadataRequest,
  refreshNftsMetadataRequest,
} from "store/actions";
import UsersColumns from "./Columns";
import SearchInput from "components/Common/SearchInput";
import MetadataUploadModal from "../Components/MetadataUploadModal";
import { downloadNftsMetadata } from "helpers/backend_helper";
const NftsMetadataList = (props) => {
  const {
    accessLevel,
    history,
    nftsMetadataList,
    totalNftsMetadata,
    getNftsMetadataRequest,
    refreshNftsMetadataRequest,
    refreshNftsMetaLoading,
    loading,
  } = props;

  console.log("nftsMetadataList", nftsMetadataList);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [searchText, setSearchText] = useState("");
  const [showMetadataModal, setShowMetadataModal] = useState("");
  const [filter, setFilter] = useState({
    // orderBy: "date_created_utc",
    // order: -1,
    page: 1,
    limit: 10,
    type: "og_mint",
  });
  const [downloading, setDownloading] = useState(false);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalNftsMetadata,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  useEffect(() => {
    getNftsMetadataRequest(filter);
  }, [filter]);

  const onClickRefreshFromS3 = () => {
    refreshNftsMetadataRequest({ type: filter.type }, () => {
      getNftsMetadataRequest({ ...filter, page: 1 });
    });
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  const onSelectType = (type) => {
    setFilter((prevState) => ({ ...prevState, type: type, page: 1 }));
  };

  const nftTypeOptions = [
    {
      label: "Syndicate",
      value: "og_mint",
    },
    {
      label: "special weapons",
      value: "claim_weapon",
    },
    {
      label: "Legendary Villains",
      value: "claim_villain",
    },
    {
      label: "Origin",
      value: "claim_origin",
    },
  ];

  const toggleConfirmModal = () => {
    setShowMetadataModal(!showMetadataModal);
  };

  const onDownload = async () => {
    try {
      setDownloading(true);
      const res = await downloadNftsMetadata({ nft_type: filter.type });
      console.log("res", res);
      if (res.data?.length) {
        const textData = `[${res.data
          .map((obj) => JSON.stringify(obj, null, 2))
          .join(",")}]`;

        // Create a Blob from the text data
        const blob = new Blob([textData], { type: "text/plain" });

        // Create a link and trigger the download
        const fileName = nftTypeOptions
          .find((item) => item?.value === filter.type)
          .label.replace(" ", "-");
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${fileName}.txt`;

        // Append the link to the body and trigger the click event
        document.body.appendChild(downloadLink);
        downloadLink.click();
        setDownloading(false);
        // Remove the link from the body
        document.body.removeChild(downloadLink);
      }
    } catch (err) {
      setDownloading(false);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Nfts Metadata"
            breadcrumbItem={props.t("Nfts Metadata")}
            breadcrumbItems={[{ title: props.t("Nfts Metadata") }]}
          />
          <MetadataUploadModal
            isOpen={showMetadataModal}
            toggle={toggleConfirmModal}
          />

          <Row>
            <Col lg={12}>
              <Button
                className="my-3 mx-2"
                color="primary"
                onClick={onClickRefreshFromS3}
              >
                {refreshNftsMetaLoading && (
                  <Spinner size="sm" className="me-2 mr-2" />
                )}{" "}
                Refresh Nfts Metadata
              </Button>
              <Button
                className="my-3 mx-2"
                color="primary"
                onClick={() => setShowMetadataModal(true)}
              >
                Update metadata
              </Button>
              <Button
                className="my-3 mx-2"
                color="primary"
                onClick={() => onDownload()}
                disabled={downloading}
              >
                {downloading && <Spinner size="sm" className="me-2 mr-2" />}{" "}
                Download metadata
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={nftsMetadataList || []}
                        columns={UsersColumns(
                          history,
                          toggleConfirmModal,
                          props.t,
                          accesses
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4" lg="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>
                              <Col lg={3}>
                                <Input
                                  type="select"
                                  placeholder="select nft type"
                                  value={filter?.type}
                                  onChange={(e) => onSelectType(e.target.value)}
                                >
                                  {nftTypeOptions.map((item) => {
                                    return (
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </Col>
                              <Col sm="2">
                                {!!filter.search && (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: "",
                                        }));
                                        setSearchText("");
                                        setLastFields({
                                          fieldName: "storeStatus",
                                          fieldValue: "",
                                        });
                                      }}
                                      className=""
                                    >
                                      {props.t("clear filters")}
                                    </Link>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                                {/* <div className="d-flex align-items-end">
                                  
                                <svg onClick={()=>pageChange("pre")} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z" fill="currentColor"/></svg>
                                <svg onClick={()=>pageChange("next")} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z" fill="currentColor"/></svg>
                                </div> */}
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ items }) => ({
  loading: items.loading,
  nftsMetadataList: items.nftsMetadataList,
  totalNftsMetadata: items.totalNftsMetadata,
  refreshNftsMetaLoading: items.refreshNftsMetaLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getNftsMetadataRequest: (data, callback) =>
      dispatch(getNftsMetadataRequest(data, callback)),
    refreshNftsMetadataRequest: (data, callback) =>
      dispatch(refreshNftsMetadataRequest(data, callback)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(NftsMetadataList))
);
